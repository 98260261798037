import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import Individualization from '../images/a-class-compact-limousine/highlights/individualization.jpg'
import HeadUpDisplay from '../images/a-class-compact-limousine/highlights/head-up-display.jpg'
import ArtificialIntelligence from '../images/a-class-compact-limousine/highlights/artificial-intelligence.jpg'
import Linguatronic from '../images/a-class-compact-limousine/highlights/linguatronic.jpg'
import Touchpad from '../images/a-class-compact-limousine/highlights/touchpad-touchscreen.jpg'
import SpaceOffer from '../images/a-class-compact-limousine/highlights/space_offer.jpg'

import HeaderImage from '../images/a-class-compact-limousine/header-a-class-compact-limousine.jpeg'

import InterieurImage from '../images/a-class-compact-limousine/gallery/interieur-tesaser-a-class-compact-limousine.jpeg'
import ExterieurImage from '../images/a-class-compact-limousine/gallery/exterieur-tesaser-a-class-compact-limousine-2.jpeg'

import InterieurImageGallery1 from '../images/a-class-compact-limousine/gallery/gallery1-interieur-a-class-compact-limousine.jpeg'
import InterieurImageGallery2 from '../images/a-class-compact-limousine/gallery/gallery2-interieur-a-class-compact-limousine.jpeg'
import InterieurImageGallery3 from '../images/a-class-compact-limousine/gallery/gallery3-interieur-a-class-compact-limousine.jpeg'
import InterieurImageGallery4 from '../images/a-class-compact-limousine/gallery/gallery4-interieur-a-class-compact-limousine.jpeg'

import ExterieurImageGallery1 from '../images/a-class-compact-limousine/gallery/gallery1-exterieur-a-class-compact-limousine.jpeg'
import ExterieurImageGallery2 from '../images/a-class-compact-limousine/gallery/gallery2-exterieur-a-class-compact-limousine.jpeg'
import ExterieurImageGallery3 from '../images/a-class-compact-limousine/gallery/gallery3-exterieur-a-class-compact-limousine.jpeg'
import ExterieurImageGallery4 from '../images/a-class-compact-limousine/gallery/gallery4-exterieur-a-class-compact-limousine.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
  ExterieurImageGallery4,
]

const AClassCompactLimousine = () => (
  <Layout>
    <SEO title="Startseite" keywords={[`sug`, `probefahrt`]} />
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Zeit, sich kennen zu lernen. Die neue A-Klasse Kompaktlimousine.</H1>
      <p>
        Die neue Mercedes-Benz-A-Klasse ist so jung und dynamisch wie immer,
        aber so erwachsen und komfortabel wie noch nie. Sie definiert modernen
        Luxus in der Kompaktklasse völlig neu und revolutioniert das Design von
        innen. Auch technologisch setzt sie sich an die Spitze - nicht nur dank
        Mercedes-Benz User Experience. Nun kommt die neuste Generation: das
        erste Auto, das Sie versteht - und das Sie intuitiv begreifen werden.
      </p>
      {/* <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink> */}
    </Section>
    <TestDrive />
    <Section align="default">
      <H1>Was macht die neue A-Klasse so besonders?</H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={Individualization}
          title="Individualisierung"
          headline="Nie angepasst. Außer an Sie und Ihr Leben."
          description="Mit der neuen A-Klasse und MBUX (Mercedes-Benz User Experience) ist ein bisschen mehr möglich. Deshalb können Sie nicht nur die Ambientebeleuchtung Ihrer Stimmung anpassen, sondern auch die Anzeigenstile auf dem Kombiinstrument und dem Display des Multimediasystems. Ganz individuell und intuitiv zum Beispiel über die Touch-Control-Buttons am Lenkrad."
        />
        <ImageBox
          imagesrc={HeadUpDisplay}
          title="Head-up-Display"
          headline="Typisch A-Klasse, den Blick immer nach vorne richten."
          description="Augen geradeaus, geht mit dem Head-up-Display in der neuen A-Klasse ganz automatisch. Denn damit werden wichtige Informationen, wie Geschwindigkeit, Tempolimits oder Navigationshinweise, direkt in Ihr Sichtfeld projiziert. Virtuell und vollfarbig. Die Höhe und Inhalte lassen sich anpassen und per Memory-Funktion abspeichern. Wieder etwas, woran Sie in Zukunft nicht mehr denken müssen."
        />
        <ImageBox
          imagesrc={ArtificialIntelligence}
          title="Mercedes-Benz User Experience"
          headline="Kennt den Weg zu Ihrer Wohnung. Und Ihre Gewohnheiten."
          description="MBUX, die neue Mercedes-Benz User Experience, macht die neue A-Klasse intelligenter als je zuvor. Jeden Tag lernt sie dazu und vor allem ihren Fahrer ein bisschen besser kennen. Sie kann sich Ihre Lieblingssongs und den Weg zur Arbeit merken. Anhand Ihrer Gewohnheiten erkennt sie die richtigen Radiosender und zeigt sie als Favoriten an oder sie zeigt Ihnen einen schnelleren Weg, wenn auf dem üblichen Stau ist. Ganz von selbst und ganz selbstverständlich, sofern man es wünscht."
        />
        <ImageBox
          imagesrc={Linguatronic}
          title="Sprachbediensystem LINGUATRONIC"
          headline="Es gibt reichlich Redebedarf."
          description="Dank des optionalen, ganz neuen Sprachbediensystems LINGUATRONIC hört die neue A-Klasse aufs Wort. Aber noch viel mehr: Sie versteht Sie, ohne vorher Kommandos lernen zu müssen. Und spricht mit Ihnen. SMS vorlesen oder diktieren und abschicken? Kein Problem. Sie checkt für Sie das Wetter am Zielort, wechselt den Radiosender oder bringt Sie auf schnellstem Weg nach Hause. Nur zwei Worte genügen: „Hey Mercedes“. Schon ist die neue A-Klasse ganz Ohr."
          subDescription="Nur erhältlich als Paketbestandteil in Verbindung mit den Ausstattungen Erweiterte Funktionen MBUX und Touchpad."
        />
        <ImageBox
          imagesrc={Touchpad}
          title="Touchpad und Touchscreen"
          headline="Die Bedienung mit dem gewissen Touch."
          description="Stellen Sie sich vor, Sie könnten mit dem kleinen Finger ein ganzes Auto bedienen. Perfekt, oder? Dann stellen wir Ihnen jetzt das Touchpad vor, mit dem Sie fast alles einstellen können. Zum Beispiel das Navigationssystem oder die Ambientebeleuchtung. Selbst Ihre Handschrift kann die berührungssensitive Oberfläche erkennen. Noch einfacher und noch direkter geht’s mit dem Touchscreen. Hier reicht eine Bewegung, schon macht es klick."
        />
        <ImageBox
          imagesrc={SpaceOffer}
          title="Raumangebot"
          headline="Raumangebot"
          description="Trotz sportlicher Optik bietet die neue A-Klasse erheblich mehr Nutzwert. Ein höheres Raumangebot und ein einfacherer Einstieg in den Fond zählen zu den Pluspunkten wie der größere und besser für Familie und Freizeit nutzbare Kofferraum. Auch die Rundumsicht wurde deutlich verbessert. Davon profitieren Sicherheit und Raumgefühl."
        />
      </SimpleSlider>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
  </Layout>
)

export default AClassCompactLimousine
